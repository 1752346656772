import './styles/main.scss';

// Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap';

import Alpine from 'alpinejs';
import './js/main.js';
import './js/login';
import './js/clubs';

window.Alpine = Alpine;
Alpine.start();
