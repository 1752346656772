import { Carousel } from 'bootstrap';

document.addEventListener('DOMContentLoaded', main);

function main() {
	const myCarouselElement = document.querySelector('#carouselExampleIndicators');
	const carouselDescriptionsEl = document.querySelector('#carousel-descriptions');
	const carouselNav = Array.from(document.querySelectorAll('#carousel-nav li'));

	const carouselImgs = myCarouselElement
		? new Carousel(myCarouselElement, {
				interval: 5000,
				touch: false,
				ride: 'carousel',
				pause: false,
		  })
		: false;

	const carouselDescriptions = carouselDescriptionsEl
		? new Carousel(carouselDescriptionsEl, {
				interval: 5000,
				touch: false,
				ride: 'carousel',
				pause: false,
		  })
		: false;

	carouselNav.forEach((navItem) => {
		navItem.addEventListener('click', () => {
			if (myCarouselElement) {
				carouselImgs.pause();
				carouselImgs.to(navItem.dataset.bsSlideTo);
			}

			if (carouselDescriptionsEl) {
				carouselDescriptions.pause();
				carouselDescriptions.to(navItem.dataset.bsSlideTo);
			}
		});
	});

	if (myCarouselElement) {
		myCarouselElement.addEventListener('slide.bs.carousel', (event) => {
			carouselNav.forEach((item) => {
				item.classList.remove('active');
			});
			carouselNav[event.to].classList.add('active');
		});
	}
}
