import Alpine from 'alpinejs';

Alpine.data('clubs', () => ({
	allSites: [],
	sites: [],
	totalMembers: 0,
	currentSort: null,
	buttons: {
		newest: document.querySelector('#sortNewest'),
		mostMembers: document.querySelector('#sortMostMembers'),
	},
	sortType: {
		MOST_MEMBERS: 'MOST_MEMBERS',
		NEWEST: 'NEWEST',
	},
	query: '',
	async init() {
		this.query = new URL(document.location).search;
		this.currentSort = this.sortType.MOST_MEMBERS;
		fetch('https://api.metafabric.io/api/general/sites')
			.then((response) => response.json())
			.then((data) => {
				if (data && data.sites) {
					let sites = Object.values(data.sites).map((site, indx) => ({
						id: indx,
						...site,
						domain: `https://${site.domain}${this.query}`,
					}));
					this.allSites = [...sites];
					this.sites = [...this.allSites];
					this.totalMembers = data.total_members;
				}
			})
			.then(() => {
				this.sort(this.currentSort);
			});
	},
	sort(type) {
		switch (type) {
			case this.sortType.MOST_MEMBERS:
				this.sites.sort((a, b) => b.count - a.count);
				this.currentSort = this.sortType.MOST_MEMBERS;
				this.buttons.mostMembers.checked = true;
				break;
			case this.sortType.NEWEST:
				this.currentSort = this.sortType.NEWEST;
				this.sites.sort((a, b) => b.created_at.localeCompare(a.created_at));
				this.buttons.newest.checked = true;
				break;
			default:
				break;
		}
	},
	searchPhrase: '',
	filterSites() {
		if (this.searchPhrase.length === 0) {
			this.sites = [...this.allSites];
			this.sort(this.currentSort);
			return;
		}
		this.sites = this.allSites.filter(
			(site) => site.name.toLowerCase().search(this.searchPhrase.toLowerCase()) !== -1
		);
		this.sort(this.currentSort);
	},
}));
