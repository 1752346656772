import Alpine from 'alpinejs';

Alpine.data('login', () => ({
	allSites: [],
	sites: [],
	isLoading: false,
	selectedClub: null,
	cssClasses: {
		SELECTED_SORTING: 'selected-sorting',
		SELECTED_CLUB: 'selected-club',
	},
	priorityClubs: ['punksclub.io', 'apeclub.ai', 'club.metafabric.io', 'onlygobs.chat'],
	buttons: {
		newest: document.querySelector('#newest_btn'),
		mostMembers: document.querySelector('#most_members_btn'),
	},
	sortType: {
		MOST_MEMBERS: 'MOST_MEMBERS',
		NEWEST: 'NEWEST',
	},
	query: '',
	async init() {
		this.isLoading = true;
		this.query = new URL(document.location).search;
		fetch('https://api.metafabric.io/api/general/sites')
			.then((response) => response.json())
			.then((data) => {
				if (data && data.sites) {
					const sites = Object.values(data.sites)
						.map((site, indx) => ({ id: indx, ...site }))
						.filter((site) => site.name !== 'BitColl');
					const prioritySites = sites.filter((site) => this.priorityClubs.includes(site.domain));
					this.allSites = [
						...prioritySites,
						...sites.filter((site) => !this.priorityClubs.includes(site.domain)),
					];
					this.sites = [...this.allSites];
				}
				this.isLoading = false;
			})
			.catch(() => {
				this.isLoading = false;
			});
	},
	clearSortButtons() {
		Object.values(this.buttons).forEach((button) => {
			button.classList.remove(this.cssClasses.SELECTED_SORTING);
		});
	},
	sort(type) {
		this.clearSortButtons();
		switch (type) {
			case this.sortType.MOST_MEMBERS:
				this.sites.sort((a, b) => b.count - a.count);
				this.buttons.mostMembers.classList.add(this.cssClasses.SELECTED_SORTING);
				break;
			case this.sortType.NEWEST:
				this.sites.sort((a, b) => b.created_at.localeCompare(a.created_at));
				this.buttons.newest.classList.add(this.cssClasses.SELECTED_SORTING);
				break;
			default:
				break;
		}
	},
	searchPhrase: '',
	filterSites() {
		this.clearSortButtons();
		if (this.searchPhrase.length === 0) {
			this.sites = [...this.allSites];
			return;
		}

		this.sites = this.allSites.filter(
			(site) => site.name.toLowerCase().search(this.searchPhrase.toLowerCase()) !== -1
		);
	},
	selectClub(club) {
		if (this.selectedClub !== null) {
			document.querySelector(`#club_${this.selectedClub.id}`).classList.remove(this.cssClasses.SELECTED_CLUB);
		}
		this.selectedClub = club;
		const element = document.querySelector(`#club_${club.id}`);
		element.classList.add(this.cssClasses.SELECTED_CLUB);
		element.scrollIntoView({ block: 'end', behavior: 'smooth' });
	},
	handleContinue() {
		if (this.selectedClub === null) {
			return;
		}
		window.location.href = `https://${this.selectedClub.domain}/auth/login${this.query}`;
	},
}));
